import React, { memo, useMemo } from "react"

export const withAccountRegisterForm = Component =>
  memo(({ name = "AccountRegisterForm", data, errors, handleChange, handleSubmit, loading, locales }: any) => {
    Component.displayName = name
    return useMemo(
      () => <Component data={data} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} locales={locales} />,
      [data, errors, handleChange, handleSubmit, loading, locales]
    )
  })
