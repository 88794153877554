import React, { memo, useCallback, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useCustomerRegister } from "../../../hooks/useCustomer"

export const withAccountRegister = Component =>
  memo(({ name = "AccountRegister", page }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { createCustomer, data, setData, loading, errors } = useCustomerRegister()

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()
        const filteredData = Object.entries(data).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
        await createCustomer({
          ...filteredData,
        })
      },
      [createCustomer, data]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setData]
    )

    const successContent = sanityContent(page?.successContent)
    const success = errors?.filter(({ code }) => code === `CUSTOMER_DISABLED`)?.length > 0

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          data={data}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          page={page}
          success={success}
          successContent={successContent}
        />
      ),
      [data, errors, handleChange, handleSubmit, loading, page, success, successContent]
    )
  })
