import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../../hoc/withoutAuthentication"
import { AccountRegister as Page } from "../../components/Account/Register/AccountRegister"

export const query = graphql`
  query {
    page: sanityPageAccountRegister {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalFirstName
      additionalLastName
      additionalEmail
      additionalPassword
      additionalSubmit
      successTitle
      successContent: _rawSuccessContent(resolveReferences: { maxDepth: 2 })
    }
    template: sanityTemplateAccount {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = withoutAuthentication(({ data, ...props }) => <Page {...props} {...data} />)

export default Component
