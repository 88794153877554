import React from "react"

import { withAccountRegister } from "./withAccountRegister"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { Icon } from "../../Icon/Icon"
import { AccountRegisterForm } from "./Form/AccountRegisterForm"
import { StyledContainer } from "../../Styled/Container"
import { StyledRow } from "../../Styled/Row"
import { Page, Title, Content } from "./styledAccountRegister"

export const AccountRegister = withAccountRegister(
  ({ data, errors, handleChange, handleSubmit, loading, page, success, successContent }): JSX.Element => (
    <Page>
      <Breadcrumbs background={`orange-pastel`} colour={`dark`} page={page} />
      {success ? (
        <StyledContainer width={`lg`}>
          <StyledRow justify={`center`} withSpacing>
            <Icon colour={`green`} name={`tick`} width={96} />
          </StyledRow>
          <Title as={`h1`} align={`center`} withSpacing={`sm`}>
            {page?.successTitle}
          </Title>
          <Content align={`center`} colour={`grey-darker`}>
            {successContent}
          </Content>
        </StyledContainer>
      ) : (
        <StyledContainer width={`md`}>
          <Title as={`h1`} align={`center`} withSpacing>
            {page?.title}
          </Title>
          <AccountRegisterForm data={data} errors={errors} handleChange={handleChange} handleSubmit={handleSubmit} loading={loading} locales={page} />
        </StyledContainer>
      )}
    </Page>
  )
)
